import citytelecom from "../images/homeLogo2/city.svg";
import wifi2biz from "../images/homeLogo2/wifi2biz.svg";
import networktooffice from "../images/homeLogo2/setoffice.svg";
import onecam from "../images/homeLogo2/onecam.svg";
import datahouse from "../images/homeLogo2/datahouse.svg";
import hoster from "../images/homeLogo2/hoster.svg";
import domenus from "../images/homeLogo2/domenus.svg";
import netron from "../images/homeLogo2/netron.svg";
import platforma from "../images/homeLogo2/platforma.svg";
import yptp from "../images/homeLogo2/yptp.svg";
import bezreg from "../images/homeLogo2/bezreg.svg";

export const services = [
  {
    title: 'Интернет в офис',
    logo: citytelecom,
    content: 'Любая компания стремится к сотрудничеству с лучшим Интернет-провайдером. Проект Citytelecom.ru присутствует в качестве основного Интернет-провайдера на 500+ объектах коммерческой недвижимости. Мы гарантируем быстрое подключение, высокую скорость передачи данных, выгодные тарифы и надёжное соединение. Помимо доступа в интернет к вашим услугам: организация сетевой инфраструктуры, построение Wi-Fi зон и многое другое.',
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '1',
  },
  {
    title: 'Телефония и ВАТС',
    logo: citytelecom,
    content: 'Офисная телефония с виртуальной АТС являются неотъемлемой частью современных бизнес-процессов. Большой номерной фонд, гибкие условия подключения в комплексе с высоким качеством передачи голосовых сообщений гарантируют отличную скорость и мобильность в решении повседневных задач.',
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '1',
  },
  {
    title: 'Авторизация Wi-Fi',
    logo: wifi2biz,
    content: 'Продвинутая система авторизации в публичных Wi-Fi сетях представлена успешным маркетинговым инструментом. WiFi2BiZ расскажет о ваших посетителях и поможет сформировать маркетинговые активности, направленные на увеличение чека.',
    url: 'https://wifi2biz.ru/',
    name: 'wifi2biz',
    category: '1',
  },
  {
    title: 'Монтаж СКС',
    logo: networktooffice,
    content: 'Строительство и обслуживание собственных волоконно-оптических трасс послужило стартом для целого направления по проектированию и монтажу ВОЛС и СКС. Наши основные ценности - профессионализм, аккуратность, гарантии сроков и качества. Благодаря такому подходу мы выстроили сотрудничество с крупными заказчиками на постоянной основе.',
    url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/',
    name: 'networktooffice',
    category: '1',
  },
  {
    title: 'Видеонаблюдение и СКУД',
    logo: networktooffice,
    content: 'Современное офисное пространство не обходится без систем видеоаналитики и систем контроля доступа. Наши решения направлены на организацию пропускных, барьерных и аналитических систем с хранением данных в облаке. С помощью них легко контролировать графики работ, учитывать посещаемость, анализировать поведенческие факторы, формировать собственные модели наблюдения.',
    url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/',
    name: 'networktooffice',
    category: '1',
  },
  {
    title: 'Видеонаблюдение в облаке',
    logo: onecam,
    content: 'Подключение к сервису облачного обмена данными Onecam обеспечит доступ к изображению из любой точки мира. Преимуществом решения является возможность подключения и настройки любого оборудования из любой сети. Просмотр изображения доступен с любых устройств в реальном времени или в режиме архива.',
    url: 'https://onecam.ru/',
    name: 'onecam',
    category: '1',
  },
  {
    title: 'IaaS и SaaS решения',
    logo: datahouse,
    content: <>Облачная платформа представлена решениями на базе VMware и вычислительными сервисами от Datahouse Cloud.
      Ценность услуги в том, что вы можете одинаково успешно решить краткосрочные локальные задачи или развернуть
      IT&#8209;инфраструктуру организации в облаке. Профессиональные системные администраторы и DevOps&#8209;инженеры
      возьмут на себя миграцию и сопровождение проекта.</>,
    url: 'https://datahouse.ru/',
    name: 'datahouse',
    category: '1',
  },
  {
    title: 'Аренда серверов',
    logo: datahouse,
    content: 'Аренда серверного оборудования в наличии и под заказ в сети дата-центров в Москве, Санкт-Петербурге и Екатеринбурге. Успешность проекта продиктована внимательным подходом к задачам заказчика, наличием SLA, высоким уровнем надёжности и связанности, благодаря которому мы обеспечиваем высочайший уровень обмена данными.',
    url: 'https://datahouse.ru/',
    name: 'datahouse',
    category: '1',
  },
  {
    title: 'Размещение в ЦОД',
    logo: datahouse,
    content: 'Размещение оборудования в сети дата-центров Tier lll позволяет исключить дополнительные затраты на содержание серверной, обеспечить высокий уровень обмена данными и продлить эксплуатационные качества оборудования. К вашим услугам профессиональные сетевые инженеры, удалённые помощники, системные администраторы.',
    url: 'https://datahouse.ru/',
    name: 'datahouse',
    category: '1',
  },
  {
    title: 'Хостинг',
    logo: hoster,
    content: 'Виртуальный хостинг - это одна из важнейших составляющих успешного бизнеса. Мы предоставляем хостинг с 1999 года и делаем акцент на надёжность и стабильность услуг. Низкая стоимость, широкий выбор тарифов, наличие облачных решений, функциональная панель управления и размещение в собственном ЦОД Tier lll выгодно отличают нас от конкурентов.',
    url: 'https://hoster.ru/',
    name: 'hoster',
    category: '1',
  },
  {
    title: 'Домены',
    logo: domenus,
    content: 'Регистрация доменных имён в аккредитованном регистраторе. Более 350 доменных зон, интеллектуальный подбор доменов и бесплатная поддержка DNS. Гарантией надёжности является опыт оказания услуг и застрахованная деятельность регистратора. Уникальные условия партнёрской программы позволили привлечь многих крупных игроков доменного рынка.',
    url: 'https://www.domenus.ru/',
    name: 'domenus',
    category: '1',
  },
  {
    title: 'Подключение ОКН',
    logo: citytelecom,
    content: 'Комфортное сотрудничество с более чем 500 бизнес-центрами и объектами коммерческой недвижимости достигается за счёт комплексного подхода и гибких индивидуальных решений. Синергия проектов ФИЛАНКО позволяет предоставить весь спектр телеком-услуг, от установки сетевого оборудования и организации рабочих мест до строительства волоконно-оптических линий связи, организации серверных помещений и созданию Wi-Fi пространств.',
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '2',
  },
  {
    title: 'Аудит сетей',
    logo: networktooffice,
    content: 'Полный комплекс мероприятий, направленных на детальное обследование и тестирование текущего состояния всех элементов сетевой инфраструктуры заказчика. Включая ВОЛС, ДРС, СКС, СВН, СКУД. Результатом аудита является исполнительная документация с текущим расположением кабельных трасс и оборудования, дополненная экспертным заключением по модернизации и оптимизации существующей слаботочной инфраструктуры.',
    url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/',
    name: 'networktooffice',
    category: '2',
  },
  {
    title: 'Строитетьство сетей',
    logo: networktooffice,
    content: 'Проектирование, строительство и обслуживание волоконно-оптических линий связи и слаботочных сетей любой сложности. Подключение объекта к последней миле, организация собственных каналов, разводка слаботочной сети внутри объекта и аварийные работы. Мы гордимся опытом проектировщиков, мастерством монтажных бригад, чёткими сроками, аккуратностью и возможностью работать с любыми видами кабельных трасс.',
    url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/',
    name: 'networktooffice',
    category: '2',
  },
  {
    title: <>СКУД и&nbsp;видеонаблюдение</>,
    logo: networktooffice,
    content: 'Системы контроля управления доступом и системы видеонаблюдения являются неотъемлемой частью современных ОКН. Помимо выполнения барьерных и защитных функций, такие системы принимают непосредственное участие в аналитике и оптимизации бизнес-процессов. Благодаря опыту реализации разнообразных проектов СВН и СКУД мы выработали подход, который позволяет максимально гибко совмещать данные системы, обеспечивать высокий уровень безопасности и открывать новые точки роста для бизнеса.',
    url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/',
    name: 'networktooffice',
    category: '2',
  },
  {
    title: 'Рекламные видеостойки',
    logo: citytelecom,
    content: 'Рекламная видеостойка - это дополнительный инструмент коммуникации, позволяющий разместить рекламные материалы и объявления в бизнес-центре как арендаторам, так и управляющим компаниям. Яркий, медийный, стильный и запоминающийся рекламный носитель в сочетании с возможностью выбора площадки для размещения, способен донести любую информацию до широкого круга пользователей.',
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '2',
  },
  {
    title: 'Энергоменеджмент и управление ресурсами',
    logo: platforma,
    content: 'Управление ресурсами включает в себя не только автоматический сбор и передачу данных (АСКУЭ), но и продуманную систему диспетчеризации и автоматики, которая направлена на сбор множества параметров с различных датчиков, контроллеров и систем, для выполнения автоматических повседневных или аварийных сценариев. Управление ресурсами и энергоменеджмент значительно экономит расходы и позволяет снизить риски аварийных ситуаций.',
    url: 'https://platforma.ru/',
    name: 'platforma',
    category: '3',
  },
  {
    title: 'Диспетчеризация',
    logo: platforma,
    content: 'Системы управления АСУД и АСУЗ автоматизируют большинство повседневных задач и упрощают контрольные и эксплуатационные функции на объекте. Автоматическая передача показаний ПУ, мониторинг узлов и систем, предотвращение аварийных ситуаций и прозрачная логистика сотрудников и специалистов обеспечивают качественное управление, снижают затраты и минимизируют риски возникновения аварийных и внештатных ситуаций. Наши системы Умный дом успешно работают как на коммерческих объектах, так и в МКД крупных застройщиков.',
    url: 'https://platforma.ru/',
    name: 'platforma',
    category: '3',
  },
  {
    title: 'Видеонаблюдение',
    logo: platforma,
    content: 'Видеонаблюдение на придомовой территории является необходимостью и отличной возможностью для монетизации дополнительных услуг УК. Подбор оборудования под индивидуальные требования, работа с типовыми решениями, облачное хранение архивов видеозаписей и интеграция просмотра камер в мобильное приложение УК обеспечивают высокий уровень безотказности и качества системы.',
    url: 'https://platforma.ru/',
    name: 'platforma',
    category: '3',
  },

  {
    title: 'Умная домофония',
    logo: platforma,
    content: <>
      <p>Интеграция различных вызывных панелей и систем домофоне в облако Platforma.ru позволяет предоставить сервис
        собственникам помещений широкий спектр современных инструментов доступа: проход по распознаванию, QR-коду или
        разовому числовому коду. Распределять вызов с домофона между устройствами, управлять временем звонков,
        обеспечивать свободный гостевой доступ. Преимущество решения в том, что оно может быть реализовано на
        существующих координатных системах без переделок и замены клиентского оборудования.</p>
      <p className='section3__quotetext--menu-item'>Управление доступом собственник осуществляет через мобильное
        приложение УК.</p>
    </>,
    url: 'https://platforma.ru/',
    name: 'platforma',
    category: '3',
  },
  {
    title: 'Проектно-изыскательные работы',
    logo: citytelecom,
    content: <>
      <p>Все виды проектных и изыскательных работ на объектах различной сложности.</p>
      <p className='section3__quotetext--menu-item'>Собственная геодезическая служба с современным оборудованием,
        согласование трасс с владельцами инженерных систем, соблюдение всех нормативов и формирование полного пакета
        проектной документации.</p>
    </>,
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '4',
  },
  {
    title: 'Кабельная продукция и оборудование',
    logo: netron,
    content: 'Торгово-производственная компания «НЭТРОН» обеспечивает застройщиков и интеграторов цифровых решений широким ассортиментом собственной кабельной продукцией, материалами и оборудованием для монтажных и строительных работ. Российское производство, выгодные условия, аутсорсинг снабжения, хранение на складе и поставка партиями на объект.',
    url: 'https://netron.pro/',
    name: 'netron',
    category: '4',
  },
  {
    title: 'Вынос сетей связи из пятна застройки',
    logo: citytelecom,
    content: <>
      <p>Одна из задач с которой сталкивается практически любой застройщик — вынос из пятна строительства линейно-кабельных сооружений (ЛКС). Мы обладаем опытом выноса ЛКС, принадлежащих ПАО МГТС, ПАО Ростелеком, КП МПТЦ, ПАО Мегафон, ПАО Вымпелком, АО Воентелеком, ПАО МТС и многим другим операторам связи.</p>
      <p className='section3__quotetext--menu-item'>На основании ТУ разрабатывается проектно-сметная документация, которая согласовывается с владельцами сетей и всеми заинтересованными организациями, после чего мы приступаем к работам по выносу.</p>
    </>,
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '4',
  },
  {
    title: 'Выдача справок ТУ',
    logo: citytelecom,
    content: <div className='section3__quotetext--certificates'>
      <p>При работе на объектах капитального строительства мы, как оператор связи, выдаем застройщикам
        технические условия в соответствии с имеющимися лицензиями на:</p>
      <ul>
        <li>широкополосный доступ в сеть интернет;</li>
        <li>телефонию;</li>
        <li>кабельное телевизионное вещание;</li>
        <li>проводное радиовещание;</li>
        <li>радиофикацию.</li>
      </ul>

      <div>
        В Москве всего несколько компаний, способных выдать ТУ по такому спектру услуг. Работа застройщика значительно
        упрощается, так как все необходимые согласования проводятся в рамках «единого окна».
      </div>
    </div>,
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '4',
  },
  {
    title: 'Безопасный регион',
    logo: bezreg,
    content: <>
      <p>Полный цикл интеграции системы Безопасный регион начинается с Получения технических условий в Министерстве государственного управления ИТиС МО. Далее разрабатывается проектная документация с включением следующих систем: СВН, СКС, ЛВС, СКТВ, ДФ, СКУД, ТФ, РФиОП, НСС. После этого выполняются  строительно-монтажные и пусконаладочные работы. Затем необходимо получение сертификата безопасности.</p>
      <p className='section3__quotetext--menu-item'>Мы обладаем опытом интеграции и всеми необходимыми компетенциями: наше преимущество не только в соблюдении сроков и аккуратности выполнения работ, но и в документальном сопровождении проекта.</p>
    </>,
    url: 'https://xn--90aefaj8c.xn--p1ai/',
    name: 'bezreg',
    category: '4',
  },
  {
    title: 'Радиофикация и системы оповещения',
    logo: yptp,
    content: 'Полный цикл интеграции системы Безопасный регион начинается с Получения технических условий в Министерстве государственного управления ИТиС МО. Далее разрабатывается проектная документация с включением следующих систем: СВН, СКС, ЛВС, СКТВ, ДФ, СКУД, ТФ, РФиОП, НСС. После этого выполняются  строительно-монтажные и пусконаладочные работы. Затем необходимо получение сертификата безопасности. Мы обладаем опытом интеграции и всеми необходимыми компетенциями: наше преимущество не только в соблюдении сроков и аккуратности выполнения работ, но и в документальном сопровождении проекта.',
    url: 'http://ooouptp.ru',
    name: 'yptp',
    category: '4',
  },
  {
    title: 'Умная квартира',
    logo: platforma,
    content: 'Умная квартира — высококонкурентный продукт, представляющий собой выстроенную экосистему с гибкими сценариями и программируемыми функциями. Являясь производителем оборудования и контроллеров, мы обеспечиваем проектирование и монтаж решений для умных квартир, предоставляем все необходимые интерфейсы управления и в случае необходимости выстраиваем мультисценарии с смежными системами (СВН, СОТ, видеоаналитика, ПА и т.д).',
    url: 'https://platforma.ru/',
    name: 'platforma',
    category: '4',
  },
  {
    title: 'Противопожарная автоматика',
    logo: yptp,
    content: 'Организация противопожарной автоматики — это интеграция комплекса строго регламентированных систем и решений на коммерческих объектах и в многоквартирных домах. Полный цикл включает обследование объекта, разработку технического задания, проектирование, поставку оборудования и материалов, выполнение строительно-монтажных и пуско-наладочных работ. Также мы осуществляем эксплуатацию и сопровождение выполненных систем на объектах Заказчика.',
    url: 'http://ooouptp.ru',
    name: 'yptp',
    category: '4',
  },
  {
    title: 'Аренда каналов связи',
    logo: citytelecom,
    content: 'Собственная разветвлённая волоконно-оптическая сеть в Москве, Санкт-Петербурге и Екатеринбурге позволяет арендовать каналы ёмкостью до 10 Гбит/c и оказывать услугу «последней мили». Обмен данными осуществляется напрямую, без использования дополнительного коммутационного оборудования. Преимуществами решения являются: оптимальная маршрутизация трафика через магистральных операторов, возможность оперативного увеличения пропускной способности канала и гибкая тарифная политика.',
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '5',
  },
  {
    title: 'Регистрация AS, PI, LIR',
    logo: citytelecom,
    content: 'Многим компаниям, имеющим скоростные выделенные каналы доступа в интернет, требуются собственные сетевые ресурсы AS (автономные системы) и PI-сети (блоки провайдеронезависимых адресов). CityTelecom является крупным LIR (LocalInternetRegistry) и готов предотсавить в пользование автономные системы и помочь в получении собственных блоков адресов.',
    url: 'https://citytelecom.ru/',
    name: 'citytelecom',
    category: '5',
  },

]
