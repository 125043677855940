import React from "react";
import "./YandexMap.scss";

import { Map, Placemark } from "react-yandex-maps";
import hint from "./images/hint.png";

const YandexMap = ({ coordinates = [56.825217, 60.643091] }) => {
    return (
        // apikey='f7f7545d-c993-45cb-9531-f0b0af775ebc'
        <Map
            defaultState={{ center: coordinates, zoom: 16 }}
            width={790}
            height={520}
            options={{ suppressMapOpenBlock: true }}
        >
            <Placemark
                geometry={coordinates}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: hint,
                    iconImageSize: [25, 40],
                }}
            />
        </Map>
    )
}

export default YandexMap;